import React, { createContext, useState, useEffect } from 'react';

export const TransactionContext = createContext();

export const TransactionProvider = ({ children }) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [remainingTime, setRemainingTime] = useState(300);
  const [cart, setCart] = useState([]);
  const addToCart = (ticket) => {
    setCart([...cart, ticket]);
  };
  const removeToCart = (id) => {
    const updatedCart = cart.filter((ticket) => ticket.id !== id);
    setCart(updatedCart);
  };
  const updateCart = (ticket) => {
    setCart((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((item) => item.id === ticket.id);
      newState[index].count = ticket.count;
      newState[index] = { ...newState[index] };
      return newState;
    });
  };
  const emptyCart = () => {
    setCart([]);
  };
  const [step, setStep] = useState(1);
  const firstStep = () => {
    setStep(1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const [cnpjLogin, setCnpjLogin] = useState('');
  const [cpfLogin, setCpfLogin] = useState('');
  const [emailLogged, setEmailLogged] = useState('');
  const [maxCount, setMaxCount] = useState(10);

  const [newTickets, setNewTickets] = useState([]);
  const transactionContextValue = {
    remainingTime,
    setRemainingTime,
    cart,
    setCart,
    addToCart,
    updateCart,
    removeToCart,
    emptyCart,
    step,
    firstStep,
    nextStep,
    prevStep,
    newTickets,
    setNewTickets,
    cnpjLogin,
    setCnpjLogin,
    cpfLogin,
    setCpfLogin,
    emailLogged,
    setEmailLogged,
    maxCount,
    setMaxCount,
  };

  return (
    <TransactionContext.Provider value={transactionContextValue}>
      {children}
    </TransactionContext.Provider>
  );
};
