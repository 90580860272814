const attendeeDefault = {
  name: '',
  tagName: '',
  email: '',
  emailConfirm: '',
  company: '',
  country: '',
  phone: '',
  phoneConfirm: '',
  cellPhone: '',
  cellPhoneConfirm: '',
  aceptTerms: false,
};

const cctokenDefault = {
  number: '',
  name: '',
  country: '',
  expireAt: '',
  code: '',
  installments: 1,
};

const customerDefault = {
  name: '',
  email: '',
  document: '',
  address: '',
};

const transactionDefault = {
  promoCode: 'NOCODE',
  eventId: process.env.REACT_APP_EVENT_ID,
  eventCode: 131243,
  attendees: [],
  products: [],
  paymentType: 'pix',
  customer: null,
  token: '',
  installments: 1,
};

const transactionResponseDefault = { success: false, data: null };

export {
  attendeeDefault,
  cctokenDefault,
  customerDefault,
  transactionDefault,
  transactionResponseDefault,
};
