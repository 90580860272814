import React from 'react';
import ReactDOM from 'react-dom/client';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
// import './index.css';
import App from './App';

import global_pt from './translations/pt/global.json';

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: 'pt',
  resources: {
    pt: {
      translation: global_pt,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
);
