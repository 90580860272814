import axios from 'axios';
import * as Sentry from '@sentry/react';

const getTickets = async (eventId) => {
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/prxplasticos/products`,
    headers: {
      'content-type': 'application/json',
    },
    data: {
      eventId: eventId
    }
  };

  try {
    const { data } = await axios(config);
    return data.data.products;
  } catch (error) {
    Sentry.setContext('request', { config });
    Sentry.setContext('response', error.response.data);
    Sentry.captureMessage(config.url);
    return [];
  }
};

const calcPromo = async (promoCode, productId) => {
  const encoded = encodeURIComponent(promoCode);
  const config = {
    method: 'GET',
    url: `${process.env.REACT_APP_BASE_URL}/products/${productId}/promo/${encoded}`,
    headers: {
      'content-type': 'application/json',
    },
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve(response.data.data))
      .catch((error) => {
        Sentry.setContext('request', { config });
        Sentry.setContext('response', error.response.data);
        Sentry.captureMessage('/products/:productid/promo/:promo');
        resolve(null);
      });
  });
};

export { getTickets, calcPromo };
