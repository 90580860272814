import React, { useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export const Alert = ({ isOpen, toggle, message }) => {
  useEffect(() => {
    if (isOpen) {
      const handleTabKey = (event) => {
        if (event.key === 'Tab') {
          event.preventDefault();
        }
      };
      document.addEventListener('keydown', handleTabKey);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <SweetAlert
          title=''
          onConfirm={(e) => {}}
          customClass='my-alert'
          customButtons={
            <React.Fragment>
              <button
                className='btn btn-outline-secondary w-25'
                onClick={toggle}
              >
                OK
              </button>
            </React.Fragment>
          }
        >
          {message}
        </SweetAlert>
      )}
    </>
  );
};
