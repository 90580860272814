import { Row, Col } from 'reactstrap';
import {
  onDelete,
  alphabetMask,
  emailMask,
  mobileMask,
} from '../../helpers/masks';

export const Attendee = ({
  index,
  attendee,
  fillAttendee,
  length,
  t,
  htmlId,
}) => {
  return (
    <>
      <h3 className='mb-3'>
        Informações do {length === 1 ? '' : `${index + 1}º`} participante
      </h3>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-name`}
          >
            <i className='bi bi-person me-2' />
            {'Nome *'}
          </label>
          <input
            id={`attendee-${index}-name`}
            className='form-control'
            autoComplete='off'
            value={attendee.name}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('name', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-tagName`}
          >
            <i className='bi bi-person-badge me-2' />
            {'Nome Crachá *'}
          </label>
          <input
            id={`attendee-${index}-tagName`}
            className='form-control'
            autoComplete='off'
            value={attendee.tagName}
            maxLength={20}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('tagName', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-email`}
          >
            <i className='bi bi-envelope me-2'></i>
            {t('attendee.fields.email')} *
          </label>
          <input
            type='email'
            id={`attendee-${index}-email`}
            className='form-control'
            name='email'
            autoComplete='off'
            value={attendee.email}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = emailMask(value);
              fillAttendee('email', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-emailConfirm`}
          >
            <i className='bi bi-envelope-check me-2'></i>
            {'Confirme seu email *'}
          </label>
          <input
            type='email'
            id={`attendee-${index}-emailConfirm`}
            className='form-control'
            name='emailConfirm'
            autoComplete='off'
            value={attendee.emailConfirm}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = emailMask(value);
              fillAttendee('emailConfirm', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-company`}
          >
            <i className='bi bi-building me-2'></i>
            {'Empresa/Organização/Instituição *'}
          </label>
          <input
            id={`attendee-${index}-company`}
            className='form-control'
            autoComplete='off'
            value={attendee.company}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('company', value, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-country`}
          >
            <i className='bi bi-flag me-2'></i>
            {'País *'}
          </label>
          <input
            id={`attendee-${index}-country`}
            className='form-control'
            autoComplete='off'
            value={attendee.country}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('country', value, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-phone`}
          >
            <i className='bi bi-telephone me-2'></i>
            {'Telefone fixo com codigo da cidade'}
          </label>
          <input
            id={`attendee-${index}-phone`}
            className='form-control'
            autoComplete='off'
            value={attendee.phone}
            maxLength={14}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = mobileMask(value);
              fillAttendee('phone', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.phone);
              fillAttendee('phone', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-phoneConfirm`}
          >
            <i className='bi bi-telephone me-2'></i>
            {'Confirme seu Telefone fixo'}
          </label>
          <input
            id={`attendee-${index}-phoneConfirm`}
            className='form-control'
            autoComplete='off'
            value={attendee.phoneConfirm}
            maxLength={14}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = mobileMask(value);
              fillAttendee('phoneConfirm', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.phoneConfirm);
              fillAttendee('phoneConfirm', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-cellPhone`}
          >
            <i className='bi bi-whatsapp me-2'></i>
            {'Celular para Whatsapp com codigo da cidade *'}
          </label>
          <input
            id={`attendee-${index}-cellPhone`}
            className='form-control'
            autoComplete='off'
            value={attendee.cellPhone}
            maxLength={15}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = mobileMask(value);
              fillAttendee('cellPhone', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.cellPhone);
              fillAttendee('cellPhone', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-cellPhoneConfirm`}
          >
            <i className='bi bi-whatsapp me-2'></i>
            {'Confirme seu Celular para Whatsapp *'}
          </label>
          <input
            id={`attendee-${index}-cellPhoneConfirm`}
            className='form-control'
            autoComplete='off'
            value={attendee.cellPhoneConfirm}
            maxLength={15}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = mobileMask(value);
              fillAttendee('cellPhoneConfirm', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.cellPhoneConfirm);
              fillAttendee('cellPhoneConfirm', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={12}
          className='mb-3'
        >
          <div className='form-check'>
            <input
              id={`attendee-${index}-aceptTerms`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.aceptTerms}
              onChange={(e) => {
                fillAttendee('aceptTerms', !attendee.aceptTerms, index);
              }}
            ></input>
            <label className='form-check-label'>
              <p className='text-justify'>
                {`O Congresso Brasileiro de Plásticos é iniciativa e realização do Instituto SustenPlást.
Concordo que meu nome, organização, país de residência e informações de contato serão compartilhados com o Instituto SustenPlást e o app/site oficial do 5CBP 5º Congresso Brasileiro de Plásticos.
Aceito que minhas informações sejam salvas e processadas de acordo com a política de privacidade do Instituto SustenPlást, que está adequado a LGPD - Lei Geral de Proteção de Dados.*`}
              </p>
              <p className='text-justify'>
                {`Brazilian Plastic Congress is an initiative driven by SustenPlást Institute.
I agree that my name, organization, country of residence, and contact information will be shared with SustenPlást Institute and the official 5CBP 5th Brazilian Plastic Congress website and/or app.
I accept that my information is saved and processed according to the privacy policy of SustenPlást Institute.*`}
              </p>
            </label>
          </div>
        </Col>
      </Row>
    </>
  );
};
