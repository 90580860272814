import { Row, Col } from 'reactstrap';
import {
  alphabetMask,
  emailMask,
  documentMask,
  onDelete,
} from '../../helpers/masks';
import { FREE } from '../../helpers/constants';

export const Customer = ({ customer, fillCustomer, t, paymentType }) => {
  return (
    <>
      <h3 className='mb-3'>
        {t('customer.fields.title_start')}{' '}
        {paymentType === FREE
          ? t('customer.fields.title_free')
          : t('customer.fields.title_paid')}
      </h3>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`customer-name`}
          >
            <i className='bi bi-person me-2'></i>
            {t('customer.fields.name')}
          </label>
          <input
            id={`customer-name`}
            value={customer.name}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillCustomer('name', newValue);
            }}
            autoComplete='off'
          />
        </Col>
        {/* <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`customer-document`}
          >
            <i className='bi bi-person-vcard me-2'></i>
            {'CPF / CNPJ'}
          </label>
          <input
            id={`customer-document`}
            value={customer.document}
            className='form-control'
            autoComplete='off'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = documentMask(value);
              fillCustomer('document', newValue);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, customer.document);
              fillCustomer('document', newValue);
            }}
          // maxLength={14}
          />
        </Col> */}
      </Row>
      <Row className='mb-3'>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`customer-email`}
          >
            <i className='bi bi-envelope me-2'></i>
            {t('customer.fields.email')}
          </label>
          <input
            id={`customer-email`}
            value={customer.email}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = emailMask(value);
              fillCustomer('email', newValue);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`customer-address`}
          >
            <i className='bi bi-geo-alt me-2'></i>
            {t('customer.fields.address')}
          </label>
          <input
            id={`customer-address`}
            value={customer.address}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillCustomer('address', newValue);
            }}
            autoComplete='off'
          />
        </Col>
      </Row>
    </>
  );
};
