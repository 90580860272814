import { Row, Col } from 'reactstrap';

export const Information = ({ t }) => {
  const paragraphs = t('event.description.paragraphs', { returnObjects: true });
  return (
    <Col>
      <h2 className='mt-3 mb-3 display-7'>{'Descrição do Evento'}</h2>
      <p>
        {`O 5ª Congresso Brasileiro do Plástico (5CBP) contará com a presença de palestrantes nacionais e internacionais.`}
      </p>
      <p>
        {`Com o tema “O Paradoxo dos Plásticos”, o 5CBP será realizado em São Paulo, no dia 12 de setembro. `}
      </p>
      <p>
        {`Você poderá  participar presencial ou on-line. `}
      </p>
      <p>
        {`Com a participação de especialistas de renome mundial, o 5º Congresso Brasileiro do Plástico apresenta inovações e informações significativas.`}
      </p>
      <p>
        {`O 5CBP visa abordar os desafios e as oportunidades que eles representam, além de ser referência na busca por informações científicas e comprovadas sobre o assunto. `}
      </p>
      <p>
        {`5º Congresso Brasileiro dos Plásticos `}
      </p>
      <p>
        {`Quando: 12 de setembro de 2024`}
      </p>
      <p>
        {`On line: AO VIVO pelo ZOOM e YOUTUBE`}
      </p>
      <p>
        {`Presencial: Casa Bisutti (R. Casa do Ator, 577 - Vila Olímpia - São Paulo/SP)`}
      </p>
      <p>
        {`O Congresso Brasileiro do Plástico (CBP)`}
      </p>
      <p>
        {`Iniciativa do Instituto SustenPlást, o CBP tem caráter educativo e é aberto a toda a sociedade.`}
      </p>
      <p>
        {`Tem como finalidade esclarecer e apresentar informações científicas a respeito do material plástico em diversos segmentos da sociedade.`}
      </p>
      <p>
        {`O evento, que acontece de maneira bianual, está em sua quinta edição e é conhecido por reunir palestrantes estrangeiros e brasileiros. `}
      </p>
      <p>
        {`As informações completas estão sendo divulgadas pelo site `}
        <a
          href={'https://cbplastico.com.br'}
          target={'_blank'}
        >
          {'cbplastico.com.br'}
        </a>
        {` e também podem ser acompanhadas através das mídias sociais como Facebook, Instagram, Youtube e Linkedin.`}
      </p>
      <p><b>
        {`* Meia-entrada garantida para estudantes e professores. Obrigatório apresentar carteirinha no dia do evento.`}
      </b></p>
    </Col>
  );

  function checkItem() {
    return (
      <div style={{
        width: '15px',
        height: '15px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingRight: 10
      }}>
        <img
          alt='Check'
          src={require('../../assets/images/check.png')}
          height={14}
          width={14}

        />
      </div>
    )
  }
};
