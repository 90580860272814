import {
  isCVV,
  isCreditCardNumber,
  isEmpty,
  isEmail,
  isCPForCNPJ,
  isMobile,
} from '../helpers/validation';

export const useError = () => {
  const goToFieldWithError = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document?.body?.getBoundingClientRect().top;
    const elementRect = element?.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
    document.getElementById(id).focus();
  };

  const attendeeError = {
    name: (attendee) => !isEmpty(attendee.name),
    tagName: (attendee) => !isEmpty(attendee.tagName),
    email: (attendee) => isEmail(attendee.email),
    emailConfirm: (attendee) => !isEmpty(attendee.emailConfirm),
    company: (attendee) => !isEmpty(attendee.company),
    country: (attendee) => !isEmpty(attendee.country),
    phone: (attendee) => attendee.phone,
    phoneConfirm: (attendee) => attendee.phoneConfirm,
    cellPhone: (attendee) => !isEmpty(attendee.cellPhone),
    cellPhoneConfirm: (attendee) => !isEmpty(attendee.cellPhoneConfirm),
    aceptTerms: (attendee) => attendee.aceptTerms,
  };

  const cctokenError = {
    number: (cctoken) => isCreditCardNumber(cctoken.number),
    name: (cctoken) => !isEmpty(cctoken.name),
    country: (cctoken) => !isEmpty(cctoken.country),
    expireAt: (cctoken) => !isEmpty(cctoken.expireAt),
    code: (cctoken) => isCVV(cctoken.code),
  };

  const customerError = {
    name: (customer) => !isEmpty(customer.name),
    // document: (customer) => isCPForCNPJ(customer.document),
    document: (customer) => customer.document,
    email: (customer) => isEmail(customer.email),
    address: (customer) => !isEmpty(customer.address),
  };
  return {
    attendeeError,
    customerError,
    cctokenError,
    goToFieldWithError,
  };
};
