export default function Event({ t }) {
  return (
    <>
      <h2 className='mb-3 display-7'>{t('event.name')}</h2>
      <p className='mb-0'><b>{t('event.date')}</b></p>
      {/* <p className='mb-0'>{t('event.time')}</p> */}
      <p className='mb-0'><b>{t('event.address')}</b></p>
      <a
        href={t('event.location.link')}
        target={'_blank'}
        rel='noreferrer'
      >
        <b>{t('event.location.text')}</b>
      </a>
    </>
  );
}
