import './styled.css';
import Banner from '../../assets/images/banner.webp';

export default function Header() {
  return (
    <div className='d-flex justify-content-center mb-5'>
      <img
        alt='Banner do evento'
        src={Banner}
        className='header-img'
      />
    </div>
  );
}
